*,
*::before,
*::after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  height: $header-height-mobile;
  width: 100%;
  max-width: $container-main-max-width;

  background: $primary;

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    height: $header-height;
    padding-left: calc($container-main-padding-x + rem(40px));
    padding-right: $container-main-padding-x;
    margin-left: auto;
    margin-right: auto;

    background: $white;
  }
}

// Header background for Promo-page
.bg-sm-info {
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    background: $info;
  }
}

.bg-sm-white {
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    background: $white;
  }
}

.container-header-promo {
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    position: fixed;
    top: 0;
    z-index: 3;

    height: 100px;
    width: 100%;
  }
}

.container-main {
  max-width: $container-main-max-width;
  padding: $container-main-padding-y-mobile $container-main-padding-x-mobile;
  margin: rem(50px) auto rem(20px);

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    padding: $container-main-padding-y $container-main-padding-x $container-main-padding-y calc($container-main-padding-x + rem(40px));
    margin: rem(100px) auto rem(20px);
  }
}

// Home page styles start

.container-home {
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    max-width: $container-main-max-width;
    padding-left: 350px;
    margin: 0 auto;
  }
}

.nav-home {
  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    column-count: 2;
    column-gap: rem(30px);
  }
}

.nav-home-item {
  break-inside: avoid;
}

// Home page styles end

.nav-underline {
  .nav-link {
    margin-bottom: -1px;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    font-weight: normal;

    &:hover {
      border-bottom-color: currentColor;
    }
  }
}

.alert {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  max-width: 600px;
  z-index: 1050;

  margin-left: rem(8px);
  margin-right: rem(8px);

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    margin-left: auto;
    margin-right: auto;
  }
}

// Product description style

[id="descriptionTabPane"] {
  p {
    margin: 0;
  }

  .fw-bold-mobile {
    font-weight: bold;

    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
      font-weight: normal;
    }
  }

  table {
    caption-side: top;
    width: auto;
    margin: rem(16px) 0;
  }

  caption {
    padding: 0;
    color: $dark;
    text-align: center;
  }

  td {
    vertical-align: middle;
    padding: rem(4px) rem(8px);
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $secondary;
  }
}

// Category description style
[id="categoryDescriptionDetails"] {
  font-size: 12px;
  line-height: 1.5;

  a {
    color: $secondary;

    &:hover {
      color: $orange;
    }
  }
}

// Rotate Select icon start

[aria-expanded="true"] {
  .ico-rotate {
    transform: rotate(-180deg);
    transition: transform 0.3s ease-in-out;
  }
}

[aria-expanded="false"] {
  .ico-rotate {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
}

// Rotate Select icon end

.font-secondary {
  font-family: $font-family-secondary;
}

.icon-link {
  font-family: $font-family-secondary;
}

.btn {
  .btn-check:checked + & {
    color: $dark;
    background-color: transparent;
  }

  &:hover,
  .btn-check:hover + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: $white;
    background-color: $orange;
    border-color: $orange;
  }

  // Button submit for filters
  &.btn-filters-submit {
    position: fixed;
    bottom: -50px;
    z-index: 1;
    width: calc(100% - ($container-main-padding-x-mobile * 2));
    transition: bottom 0.3s ease;

    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
      width: calc(100% - (($container-main-padding-x * 2) + $sidebar-caller-width));
    }

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      width: 250px;
    }

    &.visible {
      bottom: 30px;
    }
  }

  // Fix DateRangePicker input
  & > .form-control-sm {
    min-height: auto;
    border: none;
    color: inherit;
    background-color: inherit;
  }
}

.form-check-input,
.form-check-label {
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $dark;
    border-color: $dark;
  }

  &:hover + &,
  &:checked + & {
    color: $dark;
  }
}

input[type="file"]:hover + .btn {
  background-color: $orange;
  border-color: $orange;
  color: $white;
}

.l-active {
  color: $orange;
}

.link-white {
  &:hover,
  &.active {
    opacity: 0.6;
  }
}

.popover {
  max-height: $popover-max-height;
  overflow: scroll;

  .popover-body {
    font-size: $popover-body-font-size;
  }
}

.img-thumb {
  width: 160px;
  height: 160px;
}

@each $max-width-name, $max-width-value in $max-width {
  .mw-#{$max-width-name} {
    max-width: $max-width-value;
  }
}
