.logo {
  width: $logo-width;
  height: $logo-height;
}

.logo-primary {
  color: $primary;
}

.logo-white {
  color: $white;
}

.logo-primary-sm {
  color: $white;

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    color: $primary;
  }
}
