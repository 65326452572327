.input-text {
  position: relative;

  display: flex;
  flex-grow: 1;
  align-items: center;
}

.input-text-item {
  position: absolute;
  right: rem(10px);

  color: $secondary;
}
