.sidebar-home {
  position: fixed;
  width: rem(370px);
  height: 100vh;
  top: 0;
  transform: translateX(-420px);
}

.sidebar-caller {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  width: $sidebar-caller-width;
  height: $header-height-mobile;

  background: $primary;

  cursor: pointer;

  transition: width $offcanvas-transition-duration ease-in-out;

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    height: 100vh;

    &:hover {
      width: calc($sidebar-caller-width * 1.1);
    }
  }
}

.sidebar-burger {
  position: absolute;
  top: rem(16px);
  left: rem(8px);

  width: rem(24px);

  .sidebar-burger-ico,
  .sidebar-burger-ico::before,
  .sidebar-burger-ico::after {
    position: absolute;
    right: 0;
    width: rem(24px);
    height: rem(3px);
    background: $white;

    transition: width $offcanvas-transition-duration ease-in-out;
  }

  .sidebar-burger-ico::before,
  .sidebar-burger-ico::after {
    content: "";
  }

  .sidebar-burger-ico::before {
    top: rem(7px);
  }

  .sidebar-burger-ico::after {
    top: rem(14px);
  }

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    top: rem(40px);
  }
}

.sidebar-caller.active {
  .sidebar-burger-ico,
  .sidebar-burger-ico::before,
  .sidebar-burger-ico::after {
    width: rem(4px);

    transition: width $offcanvas-transition-duration ease-in-out;
  }
}

.sidebar-col {
  width: 100vw;
  height: 100%;

  &.hide-left {
    transform: translateX(-100vw);
  }

  &.hide-right {
    position: absolute;
    transform: translateX(100vw);
  }

  &.show {
    position: absolute;
    transform: none;
  }

  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    width: rem(370px);
    position: static !important;

    transform: none !important;
  }
}

.sidebar-header,
.sidebar-body {
  padding-left: $sidebar-padding-x;
  padding-right: $sidebar-padding-x;
}

.sidebar-header {
  height: $header-height-mobile;

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    height: $header-height;
  }
}
