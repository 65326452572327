// Navigation
//!! Bootstrap variables
//!! Datepicker variables
//!! Custom variables

//!! BOOTSTRAP VARIABLES START

// Options
$enable-caret: false;
$enable-dark-mode: false;
$enable-grid-classes: false;
$enable-cssgrid: true;

// gray-color-variables
$white: #fff;
$gray-100: #f5f7f9; // gray background
$gray-300: #d8dfe7; // $border-color
$gray-600: #b7c3d1; // $secondary, $text-muted, placeholder
$gray-900: #000000; // $dark, $body-color

// Color
$blue: #0378e0; // $primary
$light-blue: #e2f1fc;
$green: #57c41d; // $success
$red: #ff001f; // $danger
$orange: #fd871f; // link's hover

// Theme-color-variables
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $light-blue;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $light-blue,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "white": $white
);

$min-contrast-ratio: 4.3; // to display white and primary text in buttons ($color: color-contrast($background))
$color-contrast-dark: $primary;

// Links
$link-decoration: none;
$link-hover-color: $orange;

// Icon-link
$icon-link-icon-size: rem(20px);

// Border
$border-radius: rem(4px);

// Typography
$font-family-sans-serif: Arial, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-secondary: "HeliosExtWeb", sans-serif;

$font-size-base: 0.875rem; // 14px
$sub-sup-font-size: 0.875em;

$line-height-sm: 1.2;
$line-height-base: 1.4;
$line-height-lg: 1.5;

$h1-font-size: rem(24px);
$h2-font-size: rem(18px);
$h3-font-size: rem(16px);
$h4-font-size: rem(15px);

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;

$display-font-sizes: (
  1: 4.5rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.5rem,
  6: 2rem
);

$display-font-weight: 400;

$hr-margin-y: 0;
$hr-color: $gray-300;
$hr-opacity: 1;

// Table
$table-cell-padding-y: rem(20px);
$table-cell-padding-x: rem(10px);
$table-cell-padding-y-sm: rem(8px);
$table-cell-padding-x-sm: rem(8px);
$table-th-font-weight: normal;
$table-caption-color: $dark;

// Input-btn-variables
$input-btn-padding-y: 0.5625rem; // 9px
$input-btn-padding-x: 1rem; // 16px

$input-btn-padding-y-sm: 0.25rem; // 4px
$input-btn-padding-x-sm: 0.625rem; // 10px
$input-btn-font-size-sm: $font-size-base;

$input-btn-focus-box-shadow: none;

// Buttons
$btn-font-family: $font-family-secondary;
$btn-white-space: nowrap;
$btn-transition: none;

// Forms
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-height-border: 0;

$input-border-color: $gray-300;
$input-focus-border-color: $primary;
$input-placeholder-color: $secondary;

// Form-check
$form-check-input-width: 1.07em;
$form-check-label-color: $primary;
$form-check-label-cursor: pointer;
$form-check-input-active-filter: none;

$form-check-input-bg: transparent;
$form-check-input-focus-border: none;
$form-check-input-border-radius: 0;
$form-check-input-checked-color: $dark;
$form-check-input-checked-bg-color: transparent;
$form-check-input-checked-border-color: $dark;
$form-check-input-focus-box-shadow: $secondary;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 13 13'><polygon points='3.06066017 7.2033058 2 8.26396597 5.66351214 11.9274781 13.0606602 4.53033009 12 3.46966991 5.66351214 9.80615777'></polygon></svg>");

// Input-group-variables
$input-group-addon-color: $secondary;
$input-group-addon-bg: transparent;

// Form-text-variables
$form-text-color: $secondary;

// Container padding
$container-padding-x: 0;

// Navs
$nav-link-padding-y: 0;
$nav-link-padding-x: 0;
$nav-underline-border-width: 1px;
$nav-underline-link-active-color: $dark;
$nav-link-disabled-color: $dark;

// Dropdowns
$dropdown-min-width: 0;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;

$dropdown-spacer: 0;

$dropdown-border-radius: 0;
$dropdown-link-color: $primary;
$dropdown-link-hover-bg: $light-blue;
$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $gray-100;

$dropdown-item-padding-y: rem(10px);

// Modals
$modal-inner-padding: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-backdrop-bg: #ebeff3;

$modal-lg: 700px;
$modal-xl: 900px;

// Offcanvas
$offcanvas-padding-y: 0;
$offcanvas-padding-x: 0;
$offcanvas-horizontal-width: auto;
$offcanvas-border-width: 0;

// Breadcrumbs
$breadcrumb-item-padding-x: rem(4px);
$breadcrumb-divider-color: $secondary;

// Popovers
$popover-bg: $light;
$popover-max-width: 400px;
$popover-max-height: 250px; // Custom variable
$popover-border-width: 0;
$popover-border-radius: $border-radius;
$popover-body-font-size: $font-size-base;

// Cards
$card-spacer-y: 1.875rem; // 30px
$card-spacer-x: 0;
$card-border-width: 0;
$card-border-radius: 0;

// Close
$btn-close-width: 1rem;
$btn-close-color: $secondary;
$btn-close-focus-shadow: none;
$btn-close-opacity: 1;

// Progress bars
$progress-height: rem(45px);
$progress-font-size: 1rem;
$progress-bg: $light;
$progress-bar-bg: $light-blue;
$progress-border-radius: 0;

// Carousel
$carousel-control-color: $primary;
$carousel-control-opacity: 0.2;
$carousel-control-hover-opacity: 1;

// Alerts
$alert-margin-bottom: 0;
$alert-border-radius: 0;
$alert-link-font-weight: 400;
$alert-border-width: 0;

//!! DATEPICKER VARIABLES START

$dp-border-radius: 0;
$dp-dropdown-shadow: none;

$dp-font-weight-semibold: normal;

$dp-cell-size-base: 1.75rem;
$dp-cell-focus-background-color: #fff;
$dp-cell-disabled-color: $secondary;
$dp-cell-selected-background-color: $light-blue;
$dp-cell-selected-color: $dark;
$dp-cell-selected-font-weight: normal;

$dp-range-background-color: $light-blue;
$dp-range-start-end-background-color: $light-blue;

//!! CUSTOM VARIABLES START

// Logo
$logo-width: rem(200px);
$logo-height: rem(29px);

// Icons
$icon-sizes: (
  "sm": rem(16px),
  "md": rem(20px),
  "lg": rem(24px),
  "xl": rem(40px)
);

// Max-width
$max-width: (
  "xs": 90px,
  "sm": 380px,
  "md": 450px,
  "lg": 600px,
  "xl": 900px
);

// Header
$header-height: 100px;
$header-height-mobile: 50px;

// Containers
$container-main-max-width: 1320px;
$container-main-padding-y: rem(30px);
$container-main-padding-x: rem(30px);
$container-main-padding-y-mobile: rem(20px);
$container-main-padding-x-mobile: rem(20px);

// Sidebar
$sidebar-caller-width: rem(40px);
$sidebar-padding-x: rem(60px);
