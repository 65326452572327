.search-global {
  position: absolute;
  top: $header-height-mobile;
  width: 100%;
  z-index: $zindex-offcanvas-backdrop;

  @media screen and (min-width: map-get($grid-breakpoints, sm)) {
    position: relative;
    top: unset;
    max-width: 530px;
  }

  .input-text {
    position: static;

    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
      position: relative;
    }
  }

  .input-text-item {
    top: rem(-36px);
    color: $white;

    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
      top: inherit;
      color: $secondary;
    }
  }
}
