.ico {
  flex-shrink: 0;
  fill: currentcolor;
}

@each $icon-size-name, $icon-size-value in $icon-sizes {
  .ico-#{$icon-size-name} {
    width: $icon-size-value;
    height: $icon-size-value;
  }
}
