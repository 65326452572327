.datepicker {
  color: $dark;

  .datepicker-picker {
    border: 1px solid $border-color;
  }

  .datepicker-header {
    .datepicker-controls {
      .btn {
        font-weight: normal;
        color: $primary;
        border: none;

        &:hover,
        &:active {
          background: $white;
          color: $orange;
          border: none;
        }
      }
    }
  }

  .dow {
    font-weight: normal;
  }

  .datepicker-cell {
    &:not(.disabled):hover,
    &.focused:not(.selected) {
      border: 1px solid $primary;
      background: inherit;
    }

    &.selected {
      &:hover {
        background-color: $dp-cell-selected-background-color;
      }
    }
  }
}
